<template>

  <HeaderComponent :pageTitle="'My Current Problems'" />
  <div class="overflow-x-hidden">
  <main class="layout-main" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
    
    <p class="py-4 text-base md:text-base font-normal text-gray-600 text-left"> 
      I am experiencing the following problems:
    </p> 

     <!-- Grouped Problem Cards -->
     <div v-if="Object.keys(groupedProblems).length > 0">
  <!-- Loop through each category and its problems -->
  <div v-for="(problems, category) in groupedProblems" :key="category" class=" mb-8 bg-white shadow-xl shadow-gray-300/10">
    <!-- Category Title -->
    <h2 class="px-3 py-2 bg-gradient-to-r from-primary-200 to-secondary-200 text-base font-semibold text-secondary-600 rounded-t-lg">
      {{ category }}
    </h2>
    
    <!-- Loop through problems in this category -->
    <div v-for="problem in problems" :key="problem.id" class="flex items-center 
  border-b border-gray-100 px-2 mx-2 py-4 py-2">
      <FireIcon class="modal-icon" />
      <p class="text-base md:text-base font-normal text-gray-700">{{ problem.problem }}</p>
    </div>
  </div>
</div>
    <!-- No Entry Card -->
    <div v-else>    
      <p class="cf-tp-no-entries-title"> 
        No current entries
      </p>  
      <p class="cf-tp-no-entries-body"> 
        You and your therapist will add your 'Triggers' during treatment.
      </p>   
    </div>
    
  </main>
</div>
</template>

<script setup>

  import { FireIcon } from '@heroicons/vue/24/outline'
  import HeaderComponent from '../components/HeaderComponent';

</script>

<script>
export default {
  props: {
    problems: {
      type: Array,
      default: []
    },
    onClientPage: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    getProblems() {
      if (!this.onClientPage) {
        return this.$store.getters.getProblems;
      }
      return this.problems;
    },

    // Computed property to group problems by category
    groupedProblems() {
      return this.getProblems.reduce((groups, problem) => {
        // If the category doesn't exist, create an empty array for it
        if (!groups[problem.category]) {
          groups[problem.category] = [];
        }
        // Add the problem to the respective category
        groups[problem.category].push(problem);
        return groups;
      }, {});
    }
  },

  mounted() {
    if (!this.onClientPage) {
      this.$store.dispatch("fetchProblems");
    }
  },
};
</script>
