<template>

<HeaderComponent :pageTitle="'My Overall Aims'" />  
<div class="overflow-x-hidden">
<main class="layout-main" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
                 
  <!-- Aims Card -->
  <div v-if="getAims.length > 0" class="card-journey">
  <div v-for="(aim, index) in getAims" v-bind:key="aim.id" class="modal-list">
   
     <!-- No Entry Card 
    <p class="modal-aim-number">A{{ index + 1 }} </p>--> 
   
            <p class="text-body-base">{{ aim.aim }}</p>      
          </div>
        </div>
          <div v-else>  
   <!-- No Entry Card -->  
            <p class="cf-tp-no-entries-title"> 
              No current entries
            </p>  
            <p class="cf-tp-no-entries-body"> 
              You and your therapist will add your Overall Aims' during treatment.
            </p> 

      </div>
    </main>
  </div>
</template>

<script setup>
    
import HeaderComponent from '../components/HeaderComponent';

</script>

<script>
export default {
  props: {
    aims: {
      type: Array,
      default: []
    },
    onClientPage: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    getAims() {
      if (!this.onClientPage) {
        return this.$store.getters.getAims;
      }
      return this.aims;
    }
  },

  mounted() {
    if (!this.onClientPage) {
      this.$store.dispatch("fetchAims");
    }
  },
};
</script>