<!-- HeaderComponent.vue -->
<template>
  <header class="bg-page-background h-20 fixed top-0 left-0 right-0 z-30 md:top-20 md:left-64 md:px-0 md:mx-12 px-4 md:pt-4 flex items-center justify-between">
    <div class="flex items-center space-x-2">
      <!-- Back Button -->
      <button @click="$router.go(-1)" class="flex items-center">
        <ChevronLeftIcon class="-ml-1 h-7 w-auto text-gray-600 stroke-2" />
      </button>
      <!-- Page Title -->
      <h1 class="text-lg md:text-xl font-normal font-sans text-gray-700">{{ pageTitle }}</h1>
    </div>
    
    <div class="flex items-center space-x-2 md:hidden">
      <!-- Notification Bell -->
      <NotificationBell class="h-7 w-auto" />
      
      <!-- User Profile Icon -->
      <router-link to="/settings" class="flex items-center">
        <UserCircleIcon class="text-gray-700 h-7 w-auto cursor-pointer" />
      </router-link>
    </div>
  </header>
</template>

<script>
import { ChevronLeftIcon, BellIcon, UserCircleIcon } from '@heroicons/vue/24/outline';
import NotificationBell from './NotificationBell';


export default {
  name: 'HeaderComponent',
  components: {
    ChevronLeftIcon,
    BellIcon,
    UserCircleIcon
  },
  props: {
    pageTitle: {
      type: String,
      default: 'Page Title'
    }
  }
}
</script>

<script setup>
    
  import { ChevronLeftIcon, BellIcon, UserCircleIcon } from '@heroicons/vue/24/outline'

</script>
