<template>

<HeaderComponent :pageTitle="'My Interfering Life Patterns'" />
<div class="overflow-x-hidden">
<main class="layout-main" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
    
  <p class=" py-4 text-base md:text-base font-normal text-gray-700 text-left"> 
            These are my core interfering life patterns:
        </p>  
<!-- Schema Card -->
<div v-if="getLifetraps.length > 0" class="space-y-8">
  <div v-for="lifetrap in getLifetraps" :key="lifetrap.id" class="bg-white shadow-xl shadow-gray-300/10 rounded-md overflow-hidden">
    <!-- Gradient Header -->
    <div class="flex bg-gradient-to-r from-primary-200 to-secondary-200 px-3 py-3 text-base font-semibold text-secondary-600">
      <BeakerIcon class="modal-icon" />
      <p class="ml-2">{{ lifetrap.lifetrap }}</p>
    </div>

    <!-- Content -->
    <p class="px-3 py-4 text-gray-600">
      <span class="font-medium italic text-secondary-600">My Example:</span> {{ lifetrap.category }}
    </p>
  </div>
</div>
  <!-- No Entry Card -->
            <div v-else>    
              <p class="cf-tp-no-entries-title"> 
                No current entries
              </p>  
              <p class="cf-tp-no-entries-body"> 
                You and your therapist will add your 'Unhelpful Schemas' during treatment.
              </p>  

        </div>
    </main>
  </div> 
  </template>
  
  <script setup>
    
    import { ClipboardDocumentListIcon, BeakerIcon } from '@heroicons/vue/24/outline'
    import HeaderComponent from '../components/HeaderComponent';
  
  </script>
  
  <script>
  export default {
    props: {
      lifetraps: {
        type: Array,
        default: []
      },
      onClientPage: {
        type: Boolean,
        default: false
      }
    },
  
    data() {
      return {
        imageSource: "/img/webapp/case_formulation/life-traps.png",
        imageAltText: 'cactus',
      };
    },
  
    computed: {
      getLifetraps() {
        if (!this.onClientPage) {
          return this.$store.getters.getLifetraps;
        }
        return this.lifetraps;
      }
    },
  
    mounted() {
      if (!this.onClientPage) {
        this.$store.dispatch("fetchLifetraps");
      }
    },
  };
  </script>
  