<template>

<HeaderComponent :pageTitle="'My Actions'" />

<div class="overflow-x-hidden">
  <main class="layout-main" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">

 
  <div v-if="getGoalActions.length > 0">
  <!-- Loop through each goal -->
  <div v-for="(goal, index) in getGoalActions" :key="goal.id" class="mb-8 bg-white shadow-xl shadow-gray-300/10 rounded-lg">
    <!-- Goal Title -->
    <div class="bg-gradient-to-r from-primary-200 to-secondary-200 py-4 px-6 rounded-t-lg">
      <h2 class="text-sm font-semibold text-gray-700 uppercase">Goal {{ index + 1 }}</h2>
      <p v-if="goal?.goal?.goal" class="text-base font-semibold text-secondary-600">{{ goal.goal.goal }}</p>
    </div>
    
    <!-- Actions Section -->
    <div class="px-4 py-4 border-t border-gray-100">
      <h3 class="text-sm font-semibold text-gray-700 uppercase mb-4">Actions for Goal {{ index + 1 }}</h3>
      <div v-for="(action, actionIndex) in goal.actions" :key="action.id" class="mb-4 flex items-start">
        <!-- Action number circle above the action -->
        <div class="flex flex-col items-center mr-4">
          <p class="h-7 w-7 flex items-center justify-center rounded-full bg-secondary-300 text-xs font-semibold text-white">
            A{{ actionIndex + 1 }}
          </p>
        </div>
        <p class="text-base md:text-base font-normal text-gray-700">{{ action.goal_action }}</p>
      </div>
    </div>
  </div>
</div>

            <div v-else>    
              <p class="cf-tp-no-entries-title"> 
                No current entries
              </p>  
              <p class="cf-tp-no-entries-body"> 
                You and your therapist will add your 'Actions' during treatment.
              </p>    
          </div>

        </main>
      </div>
  </template>
  
  <script setup>
    import { ref } from "vue";
    import { ChevronRightIcon, PaperAirplaneIcon, XMarkIcon } from '@heroicons/vue/20/solid';
    import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';
    import HeaderComponent from '../components/HeaderComponent';
  
  </script>
  
  <script>
  export default {
    props: {
      goals: {
        type: Array,
        default: []
      },
      actions: {
        type: Array,
        default: []
      },
      onClientPage: {
        type: Boolean,
        default: false
      }
    },
  
    
    computed: {
      getGoalActions() {
        let goals = [];
        let goalActions = [];
  
        if (!this.onClientPage) {
          goals = this.$store.getters.getGoals;
          goalActions = this.$store.getters.getGoalActions;
        } else {
          goals = this.goals;
          goalActions = this.actions;
        }
  
        // Group goal actions by goal ID
        const groupedActions = goalActions.reduce((acc, action) => {
          if (acc[action.goal_id]) {
            acc[action.goal_id].actions.push(action);
          } else {
            acc[action.goal_id] = {
              id: action.goal_id,
              goal: goals.find((goal) => goal.id === action.goal_id),
              actions: [action],
            };
          }
          return acc;
        }, {});
  
        // Convert grouped actions into an array
        return Object.values(groupedActions);
      }
    },
  
    mounted() {
  if (!this.onClientPage) {
    console.log('Dispatching fetchGoals and fetchGoalActions');
    this.$store.dispatch("fetchGoals")
      .then(() => console.log('fetchGoals dispatched'))
      .catch(error => console.error('fetchGoals error:', error));
    
    this.$store.dispatch("fetchGoalActions")
      .then(() => console.log('fetchGoalActions dispatched'))
      .catch(error => console.error('fetchGoalActions error:', error));
  }
}
  };
  </script>
  