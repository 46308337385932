<template>

<HeaderComponent :pageTitle="'My Dominant Parts'" />
<div class="overflow-x-hidden">
<main class="layout-main" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
    
  <p class=" py-4 text-base md:text-base font-normal text-gray-600 text-left"> 
            When my core patterns are activated, I often engage with these parts of myself:
        </p>  


<!-- Mode Card -->
<div v-if="getEmotionalstates.length > 0" class="space-y-8">
  <div v-for="emotionalstate in getEmotionalstates" v-bind:key="emotionalstate.id" class="bg-white shadow-xl shadow-gray-300/10 rounded-md overflow-hidden">

    <!-- Gradient Header -->
    <div class="flex bg-gradient-to-r from-primary-200 to-secondary-200 px-3 py-3 text-base font-semibold text-secondary-600">
      <BeakerIcon class="modal-icon" />
      <p class="ml-2">{{ emotionalstate.emotionalstate }}</p>
    </div>

    <!-- Content -->
    <p class="px-3 py-4 text-gray-600">
      <span class="font-medium italic text-secondary-600">My Example:</span> {{ emotionalstate.category }}
    </p>
  </div>
</div>
  <!-- No Entry Card -->
            <div v-else>    
              <p class="cf-tp-no-entries-title"> 
                No current entries
              </p>  
              <p class="cf-tp-no-entries-body"> 
                You and your therapist will add your 'Schemas Modes' during treatment.
              </p>   

      </div>
    </main>
  </div>
  </template>
  
  <script setup>
    
    import { ClipboardDocumentListIcon, LifebuoyIcon } from '@heroicons/vue/24/outline'
    import HeaderComponent from '../components/HeaderComponent';
  
  </script>
  
  <script>
  export default {
    props: {
      emotionalstates: {
        type: Array,
        default: []
      },
      onClientPage: {
        type: Boolean,
        default: false
      }
    },
  
    data() {
      return {
        imageSource: "/img/webapp/case_formulation/emotional-states.png",
        imageAltText: 'art palette showing paints',
      };
    },
  
    computed: {
      getEmotionalstates() {
        if (!this.onClientPage) {
          return this.$store.getters.getEmotionalstates;
        }
        return this.emotionalstates;
      }
    },
  
    mounted() {
      if (!this.onClientPage) {
        this.$store.dispatch("fetchEmotionalstates");
      }
    },
  };
  </script>
  